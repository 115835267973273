const breakpoints = {
  // 'breakpoints': [
  //   '600px',
  //   '900px',
  //   '1200px',
  //   '1800px'
  // ],
  'breakpoints': [
    '640px',
    '768px',
    '1024px',
    '1280px',
    '1440px',
    '1920'
  ]
}

export default breakpoints
