const misc = {
  'borderWidths': {
    '0': '0',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    'px': '1px'
  },
  'radii': {
    'none': '0',
    'sm': '0.125rem',
    'default': '0.25rem',
    'lg': '0.5rem',
    'full': '9999px'
  },
  'zIndices': {
    '0': '0',
    '10': '10',
    '20': '20',
    '30': '30',
    '40': '40',
    '50': '50',
    'auto': 'auto'
  }
}

export default misc
