const lists = {
  lists: {
    reset: {
      listStyle: 'none',
      p: 0,
      m: 0
    }
  }
}

export default lists
